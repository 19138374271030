import { useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import AppLayout from './AppLayout';
import SamplePage from './SamplePage';
import SampleKeyPage from './SampleKeyPage';
import './App.scss';
import ISample from './Sample';

export default function App() {

    const [sample, setSample] = useState<ISample>();

    const onSampleLoaded = async (sample?: ISample) => setSample(sample);

    return <RouterProvider router={createBrowserRouter(createRoutesFromElements(
        <Route element={<AppLayout sample={sample} />}>
            <Route path="/:sampleKey" element={<SamplePage sample={sample} onLoaded={onSampleLoaded} />} />
            <Route path="/" element={<SampleKeyPage />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Route>
    ))} />;
}
