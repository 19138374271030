import { useId, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SampleKeyPage.scss';

export default function SampleInput() {

    const accessKeyId = useId();
    const [accessKey, setAccessKey] = useState("");
    const navigate = useNavigate();

    const onActivateClick = () => {
        navigate(`/${accessKey}`);
    }

    return <main className="to-dialog-host">
        <form className="to-form dialog-body-form">
            <div className="to-dialog-body">
                <p className="to-text standard-text form-instructions">
                    <strong>Enter your sample key</strong> and click the Activate button to setup your sample for use.
                </p>
                <div className="has-required"><span className="to-text standard-text to-form-required">* </span><span className="to-text smallest-text">Required</span></div>

                <section className="form-group">
                    <label className="label to-form-label required" htmlFor="form-group-samplekey">Sample Key<span className="to-form-required"> *</span></label>
                    <input id={accessKeyId} type="text" className="input" required={true} value={accessKey} onChange={e => setAccessKey(e.target.value)} />
                    <span className="to-text small-text sample-code">SAMPLE KEY: 79F120AB-2D5C-47A9-8AB6-187C23CAFF9B</span>
                </section>
            </div>

            <div className="to-dialog-footer to-dialog-buttons">
                <ul>
                    <li><button type="submit" className="to-button primary" onClick={onActivateClick}>Activate</button></li>
                </ul>
            </div>
        </form>
        
    </main>;
}