import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from './Api';
import ISample from './Sample';
import './SamplePage.scss';

interface ISampleProps {
    sample?: ISample;
    onLoaded: (sample?: ISample) => Promise<void>
}

export default function SamplePage({ sample, onLoaded }: ISampleProps) {

    const { sampleKey } = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            if (!sample)
                try {
                    setIsLoading(true);
                    setErrorMessage("");
                    const sample = await Api.get<ISample>(`api/sample/${sampleKey}`, controller.signal);
                    if (!sample?.activationDate)
                        setErrorMessage(`Oops! This sample needed to be used before ${sample.activationDate}. Please contact CompTIA is you wish to obtain another sample.`);
                    await onLoaded(sample);
                    setIsLoading(false);
                }
                catch (ex: any) {
                    console.log(ex);
                    if (ex.problem?.status >= 400 && ex.problem?.status <= 499)
                        setErrorMessage("Oops! We couldn't find your sample key.");
                    else if (ex.name !== "AbortError")
                        setErrorMessage("Oops! Something went wrong and we couldn't activate your sample. Please wait a bit and try refeshing the page.");
                }
        })();

        return () => {
            setIsLoading(false);
            controller.abort();
        }

    }, [sampleKey, sample, onLoaded]);

    const onCopyLoginClick = () => {
        navigator.clipboard.writeText(sample?.login ?? "");
    }

    const onCopyPasswordClick = () => {
        navigator.clipboard.writeText(sample?.password ?? "");
    }

    return errorMessage
        ? <main className="to-body center">
            <div>
                <img src="ico_error_large.png" alt="Oops" />
                <div>{errorMessage}</div>
            </div>
        </main>
        : isLoading
            ? <main className="to-body center">
                <div>
                    <div className="loader"></div>
                    <div className="to-text">Preparing your sample for use...</div>
                </div>
            </main>
            : <main className="to-dialog-host">
                <form className="to-form dialog-body-form">
                    <div className="to-dialog-body">
                        <p className="to-text standard-text form-instructions">
                            We've activated your 30 day sample of LabSim. <strong>Click the Launch LabSim button</strong> and enter the credentials below to get started.
                        </p>
                        <section className="to-info-group">
                            <div className="to-name-value">
                                <span className="label name">Login</span>
                                <div>
                                    <span className="value">{sample?.login}</span>
                                    <button type="button" title="Copy to clipboard" onClick={onCopyLoginClick}>&#xe14d;</button>
                                </div>
                            </div>
                            <div className="to-name-value">
                                <span className="label name">Password</span>
                                <div>
                                    <span className="value">{sample?.password}</span>
                                    <button type="button" title="Copy to clipboard" onClick={onCopyPasswordClick}>&#xe14d;</button>
                                </div>
                            </div>
                        </section>
                        <p>You can go directly to <a href="https://labsimapp.testout.com">https://labsimapp.testout.com</a> in your browser in the future.</p>
                        <p>Please view our <a href="https://support.testout.com/">Getting Started tutorial</a> for help using LabSim.</p>
                    </div>

                    <div className="to-dialog-footer to-dialog-buttons">
                        <ul>
                            <li><a href="https://labsimapp.testout.com" className="to-router-button-link primary" rel="noreferrer" target="_blank">Launch LabSim</a></li>
                        </ul>
                    </div>
                </form>
            </main>;
}
