import { Outlet } from 'react-router-dom';
import './AppLayout.scss';
import ISample from './Sample';

interface IAppLayoutProps {
    sample?: ISample
}

export default function AppLayout({ sample }: IAppLayoutProps) {

    return <div className="App">
        <header>
            <nav className="to-navbar dark overflow-x nav-parent">
                <a className="to-navbar-brand" href="https://labsimapp.testout.com">
                    <img src="https://cdn.testout.com/labsimsaas/images_v2/labsim_logo_small_250w.png" alt="LabSim" />
                </a>
                <div className="nav-container">
                    <ul className="to-nav main-nav"></ul>
                    <ul className="to-nav">
                        <li className="to-nav-item label-only"><span className="to-text">{((sample?.prospectFirstName ?? "") + " " + (sample?.prospectLastName ?? "")).trim()}</span></li>
                    </ul>
                </div>
            </nav>
            
        </header >
        <Outlet />
        <footer>
            <div>
                {sample?.senderEmail && <a href={"mailto:" + sample?.senderEmail} className="icon-email">Email {sample?.senderName ?? ""} &lt;{sample?.senderEmail}&gt;</a>}
                {sample?.senderCalendarUrl && <a href={sample?.senderCalendarUrl} className="icon-calendar">Schedule a meeting with {sample?.senderName ?? "me"}</a>}
            </div>
            <p className="to-text smallest-text">Copyright &copy; 2023 TestOut Corp. Copyright &copy; CompTIA, Inc. All rights reserved.</p>
        </footer>
    </div >;
}
