export default class Api {

    /** Create an object.
     * @param uri The URI of the collection where the object should be created.
     * @param obj The object to create.
     * @return (200) The created object; (4xx/5xx) an error object.
     */
    static async post<T = any, B = any>(uri: string, obj?: B): Promise<T> {
        return Api.fetch<T>(uri, "POST", obj);
    }

    /** Retrieve an object or collection of objects.
     * @param uri The URI of the object or collection to be retrieved.
     * @param abortSignal An optional signal for aborting retrieval in progress.
     * @return (200) The object or collection of objects; (4xx/5xx) an error object.
     */ 
    static async get<T = any>(uri: string, abortSignal?: AbortSignal): Promise<T> {
        return Api.fetch<T>(uri, "GET", undefined, abortSignal);
    }

    /** Retrieve a typed object.
     * @param Type The constructor to use when instantiating the object.
     * @param uri The URI of the object or collection to be retrieved.
     * @param abortSignal An optional signal for aborting retrieval in progress.
     * @return (200) The typed object; (4xx/5xx) an error object.
     */ 
    static async getObject<T = {}, T2 = {}>(Type: (new(obj?: T2) => T), uri: string, abortSignal?: AbortSignal): Promise<T> {
        return new Type(await Api.fetch<T2>(uri, "GET", undefined, abortSignal));
    }

    /** Update or replace an object.
     * @param uri The URI of the object to update or replace.
     * @param obj The new object.
     * @return (200) The updated object; (4xx/5xx) an error object.
     */ 
    static async put<T = any, B = any>(uri: string, obj: B): Promise<T> {
        return Api.fetch<T>(uri, "PUT", obj);
    }

    /** Update or modify an object.
     * @param uri The URI of the object to update or modify.
     * @param obj The object with properties to update.
     * @return (200) The updated object; (4xx/5xx) an error object.
     */ 
    static async patch<T = any, B = any>(uri: string, obj: B): Promise<T> {
        return Api.fetch<T>(uri, "PATCH", obj);
    }

    /** Delete an object.
     * @param url The URL of the object to delete.
     * @return (4xx/5xx) an error object.
     */
    static async delete<T = any>(uri: string): Promise<T> {
        return Api.fetch<T>(uri, "DELETE");
    }

    /** Perform a RESTful method on the application server.
     * @param uri The URI of an object or collection.
     * @param method The method to perform.
     * @param obj The object on which to perform the method.
     * @param abortSignal An optional signal for aborting the method in progress.
     * @return Optionally an object or error object.
     */
    static async fetch<T = any, B = any>(uri: string, method: string, obj?: B, abortSignal?: AbortSignal): Promise<T> {
        try {
            const response = await fetch(uri,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: obj ? JSON.stringify(obj) : undefined,
                    signal: abortSignal
                });
            if (response.ok) {
                const data = await response.text();
                return data.length > 0 ? JSON.parse(data) : undefined;
            } else if (response.headers.get("Content-Type")?.startsWith("application/problem+json")) {
                const problem: IProblem = await response.json() as IProblem;
                throw new ProblemError(problem);
            } else
            {
                const data = await response.text();
                const detail = data.length > 0 && data.startsWith("{") ? (JSON.parse(data).detail || "") : "";
                throw new ProblemError({
                    status: response.status,
                    title: response.statusText,
                    detail: detail
                });
            }
        }
        catch (error: any) {
            //error.message = `API "${method} ${uri}" failed. ${Api.shorten(error.message)}` + error.message;
            throw error;
        }
    }

    static shorten(message: string): string {
        // Remove the stack trace if it is there
        var truncate = message.indexOf('\n   at ');
        if (truncate > 0)
            message = message.substring(0, truncate - 1);
        return message;
    } 
}

interface IProblem {
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;
    errors?: { [key: string]: string }
}

export class ProblemError extends Error {
    constructor(problem: IProblem) {
        let message = `[${problem.status}]`;
        if (problem.title) message += ` ${problem.title}`;
        if (problem.detail) message += ` ${problem.detail}`;

        for (var error of Object.values(problem.errors || {}))
            message += ` ${error}`;
        super(message);
        this.name = "ProblemError"
        this.problem = problem;

    }
    problem: IProblem;
}
